import styled from 'styled-components'
import useSound from 'use-sound'

import clippySrc from '../../images/clippy.gif'
import errorSound from '../../sounds/effects/clippy-error.mp3'

const Image = styled.img`
  width: 100%;
`

export const useErrorSound = () => {
  return useSound(errorSound)
}

const Clippy = (props) => {
  return (
    <Image src={clippySrc} {...props} />
  )
}

export default Clippy
