import styled from 'styled-components'

const Flex = styled.div`
display: flex;
${props => props.css && props.css};
align-items: ${props => props.ai};
justify-content: ${props => props.jc};
flex: ${props => props.n || props.flex};
height: ${props => props.h};
overflow-y: ${props => props.oy};
flex-wrap: ${props => props.wrap && 'wrap'};
`

export const Column = styled(Flex)`
flex-direction: column;
`

export const Row = styled(Flex)`
flex-direction: row;
`

export default Flex
