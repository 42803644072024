import { useCallback } from 'react'
import useSound from 'use-sound'
import keyboardSound from '../sounds/effects/keyboard-click.mp3'
import mouseClickSound from '../sounds/effects/mouse-click.mp3'

export const withKeySound = (Component) =>
  ({ onChange, ...props }) => {
    const [playKey] = useSound(keyboardSound, { volume: 0.25 })
    const handleChange = useCallback((event) => {
      playKey()
      onChange?.(event)
    }, [playKey])
    return (
      <Component onChange={handleChange} {...props} />
    )
  }

export const withClickSound = (Component) =>
  ({ onClick, ...props }) => {
    const [playMouseClick] = useSound(mouseClickSound, { volume: 0.5 })
    const handleClick = useCallback((event) => {
      playMouseClick()
      onClick?.(event)
    }, [playMouseClick])
    return (
      <Component onClick={handleClick} {...props} />
    )
  }
