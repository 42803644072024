
import { AES, enc } from 'crypto-js'

export const decryptWithAES = (ciphertext, passphrase) => {
  try {
    const bytes = AES.decrypt(ciphertext, passphrase)
    const content = bytes.toString(enc.Utf8)
    const isEmpty = content != null
      ? content.trim() === ''
      : false
    return !isEmpty
      ? content
      : null
  } catch (e) {
    return null
  }
}

export const parseStrToJson = (string) => {
  try {
    return JSON.parse(string)
  } catch (error) {
    return null
  }
}

export const createVault = (identifier = 'REACT_APP_VAULTS', opts = {}) => {
  const { store = process.env } = opts
  const vaultsStr = store[identifier]
  const vaults = vaultsStr
    ? vaultsStr.split(',')
    : []
  return {
    content: null,
    vaults,
    failCount: 0,
    get (key) {
      return this.content?.[key]
    },
    unlock (passphrase) {
      const decrypted = vaults.reduce((acc, blob) => {
        const content = decryptWithAES(blob, passphrase)
        return content != null
          ? content
          : acc
      }, null)
      this.failCount = decrypted == null
        ? this.failCount + 1
        : 0
      const content = parseStrToJson(decrypted)
      this.content = content
      return content
    }
  }
}
