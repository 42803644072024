import styled, { css, keyframes } from 'styled-components'

import Button from './components/Button'
import Input from './components/Input'
import { Column, Row } from './components/Flex'
import ProfilePicture from './components/ProfilePicture'
import Window from './components/Window'
import Clippy, { useErrorSound as useClippyErrorSound } from './components/Clippy'

import { createVault } from './libraries/vault'

import LogoSrc from './images/logo-krindows.png'
import DuckSrc from './images/duck.jpeg'
import WallpaperSrc from './images/wallpaper.jpeg'
import pSmileySrc from './images/p-smiley.png'
import gif1Src from './images/animations/oh-hello.gif'
import gif2Src from './images/animations/system-malfunction.gif'
import gif3Src from './images/animations/2000s.gif'
import gif4Src from './images/animations/drink-soda.gif'
import gif5Src from './images/animations/keyboard.gif'

import errorSound from './sounds/effects/error.mp3'
import signInSound from './sounds/effects/signin.mp3'
import signOutSound from './sounds/effects/signout.mp3'

import bongoMusic from './sounds/music/bongo.mp3'

import { useCallback, useEffect, useMemo, useState } from 'react'
import useSound from 'use-sound'
import WordArtBlue from './components/WordArtBlue'

const Fill = styled.div`
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
`

const discoAnimation = keyframes`
 0% { background-color: transparent; }
 25% { background-color: blue; }
 50% { background-color: red; }
 75% { background-color: green; }
 100% { background-color: transparent; }
`

const Background = styled.div`
font-family: Arial, Helvetica, sans-serif;
  flex: 1;
  height: 100%;
  background: radial-gradient(at top left, #9DBFE9, #5090DA);
  display: flex;
  flex-direction: column;
  color: #F9F1ED;
`

const Bar = styled.div`
  background-color: #014DA3;
  width: 100%;
  height: 100px; 
  position: relative;
`

const GradientBar = styled.figure`
  margin: 0;
  padding: 0;
  height: 2px;
  position: absolute;
  width: 100%;
  ${props => props.bottom && css`bottom: 0;`};
  ${props => props.top && css`top: 0;`};
  background: linear-gradient(0.25turn, #014DA3, ${props => props.color}, #014DA3);
`

const Content = styled.div`
  flex: 1;
  padding: 5rem 0;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  gap: 2rem;
`

const Logo = styled.img`
  max-width: 256px;
`

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Accounts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`

const Account = styled.div`
  display: flex;
  max-height: 96px;
  background: linear-gradient(0.25turn, #014DA3, transparent);
  border-radius: 10px 0 0 10px;
  border: 1px solid rgba(255,255,255,0.75);
  border-right: none;
`

const Subtitle = styled.p`
  margin: 0;
  margin-bottom: 0.3rem;
  font-size: 0.75rem;
`

const Notepad = styled.div`
  font-family: 'Courier New', Courier, monospace;
  padding: 0.25rem;
`

const Wallpaper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url(${WallpaperSrc});
  background-size: cover;
  box-sizing: border-box;
  z-index: -2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ColorOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: exclusion;
  z-index: -1;
  background-color: red;
  animation-name: ${discoAnimation};
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: steps(10, end);
`

const WallpaperWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Padding = styled.div`
  padding: 1rem;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
`

const ClippyWrapper = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  width: 128px;
  z-index: 1;
`

const MessageLine = styled.p`
  margin: 0;
  line-height: 1rem;
`

const PowerButton = (props) => <Button color='red' square {...props}>⏼</Button>
const SignInButton = (props) => <Button style={{ marginLeft: '0.5rem' }} color='#0BA62E' square {...props}>➡</Button>

const vault = createVault()

const HEADERS = [
  (props) => (
    <Row jc='center'>
      <img style={{ flex: 1, height: '96px' }} src={gif1Src} />
      <img style={{ flex: 1, height: '96px' }} src={gif2Src} />
      <img style={{ flex: 1, height: '96px' }} src={gif3Src} />
      <img style={{ flex: 1, height: '96px' }} src={gif4Src} />
      <img style={{ flex: 1, height: '96px' }} src={gif5Src} />
    </Row>
  )
]

function App () {
  const [content, setContent] = useState()
  const [passphrase, setPassphrase] = useState('')
  const [veryFail, setVeryFail] = useState(false)

  const [playError] = useSound(errorSound)
  const [playSignIn] = useSound(signInSound)
  const [playSignOut] = useSound(signOutSound)
  const [playBackgroundMusic, { stop: stopBackgroundMusic }] = useSound(bongoMusic, { volume: 0.35, loop: true })
  const [playClippyError, { stop: stopClippyError }] = useClippyErrorSound()

  const isUnlocked = useMemo(() => content != null, [content])
  const handleSubmit = useCallback((event) => {
    event.preventDefault()
    const content = vault.unlock(passphrase)
    const isVeryFail = vault.failCount >= 3
    if (isVeryFail) {
      stopClippyError()
      playClippyError()
    }
    setVeryFail(isVeryFail)
    if (content == null) playError()
    setContent(vault.content)
  }, [vault, passphrase])
  const handlePassphraseChange = useCallback((event) => {
    const { value } = event.target
    setPassphrase(value.toLowerCase())
  }, [])

  useEffect(() => {
    if (isUnlocked) {
      playSignIn()
      setTimeout(() => playBackgroundMusic(), 1000)
    } else {
      playSignOut()
      setPassphrase('')
    }
    return () => {
      stopBackgroundMusic()
    }
  }, [isUnlocked])

  const Header = HEADERS?.[content?.asciiIndex] || HEADERS[0]
  const [firstMessage, ...messages] = content?.messages || []
  return (
    <Fill>
      {isUnlocked && (
        <WallpaperWrapper>
          <Padding>
            <Window
              title={content.title}
              iconSrc={pSmileySrc}
              width='640px'
              height='640px'
              onClose={() => setContent(null)}
            >
              <Header />
              <Notepad>
                <Row wrap>
                  <Column>
                    <WordArtBlue>{firstMessage}</WordArtBlue>
                    {messages.map((message, i) => <MessageLine key={i}>{message}</MessageLine>)}
                  </Column>
                  <Column>
                    <ul>
                      {content.links.map((link, i) => (
                        <li key={i}>
                          <a href={link.link} target='_blank' rel='noreferrer'>{link.title}</a>
                        </li>
                      )
                      )}
                    </ul>
                  </Column>
                </Row>
              </Notepad>
            </Window>
          </Padding>
          <ColorOverlay />
          <Wallpaper />
        </WallpaperWrapper>
      )}
      {!isUnlocked && (
        <Background>
          <Bar>
            <GradientBar bottom color='#F9F1ED' />
          </Bar>
          <Content>
            {veryFail && (
              <ClippyWrapper>
                <Clippy />
              </ClippyWrapper>
            )}
            <LogoWrapper>
              <Logo src={LogoSrc} />
              <p>MAXXA volymen</p>
            </LogoWrapper>
            <Accounts>
              <Account>
                <Column
                  jc='center'
                  css={css`
                padding: 1rem;
              `}
                >
                  <ProfilePicture src={DuckSrc} color='#F0CF5C' />
                </Column>
                <Column jc='center'>
                  <Row>
                    <Subtitle>Type your password</Subtitle>
                  </Row>
                  <Row as='form' ai='center' onSubmit={handleSubmit} autoComplete='new-password'>
                    <Input type='password' value={passphrase} onChange={handlePassphraseChange} />
                    <Row>
                      <SignInButton />
                    </Row>
                  </Row>
                </Column>
              </Account>
            </Accounts>
          </Content>
          <Bar>
            <GradientBar top color='#E29858' />
          </Bar>
        </Background>
      )}
    </Fill>
  )
}

export default App
