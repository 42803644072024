import styled from 'styled-components'

const ProfileImage = styled.img`
  border-width: 2px;
  border-style: solid;
  border-color: ${props => props.color};
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.25);
`

export default ProfileImage
