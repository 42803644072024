import styled, { css } from 'styled-components'
import { withClickSound } from '../../libraries/with'

const Button = styled.button`
${props => props.square && css`
  width: 32px;
  height: 32px;
`}
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 8px;
  color: white;
  font-size: 1.25rem;
  text-align: center;
  padding: 0;
  font-weight: bold;
  background: ${props => props.color}
    linear-gradient(0.4turn, rgba(255,255,255,0.55), rgba(0,0,0,0.25));
  box-shadow: 2px 2px 0 rgba(0,0,0,0.15),
    3px 3px 2px rgba(255,255,255,0.35) inset,
    -2px -2px 2px rgba(0,0,0,0.25) inset;
  &:active {
    box-shadow: 2px 2px 0 rgba(0,0,0,0.15),
    -3px -3px 2px rgba(255,255,255,0.35) inset,
    2px 2px 2px rgba(0,0,0,0.25) inset;
  }
`

export default withClickSound(Button)
