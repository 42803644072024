import styled, { css } from 'styled-components'
import Button from '../Button'
import { Row, Column } from '../Flex'

const Container = styled.div`
  max-width: ${props => props.width};
  max-height: ${props => props.height};  
  height: 100%;
  width: 100%;
  `

const Background = styled(Column)`
  border: 1px solid #064DC9;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  background-color: #F2F3EE;
  height: 100%;
  word-wrap: anywhere;
`

const Topbar = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  height: 40px;
  width: 100%;
  background: linear-gradient(#0053E9, #005DFA);
  box-shadow: 0px 3px 3px rgba(255,255,255,0.5) inset,
    0px -2px 2px rgba(0,0,0,0.25) inset;
  background-color: blue;
  padding: 0 0.5rem;
  box-sizing: border-box;
`

const WindowIcon = styled.img`
  width: 16px;
  margin-right: 0.25rem;
`

const WindowTitle = styled.p`
  flex: 1;
  font-weight: bold;
  margin: 0;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,0.75);
`

const VertBorder = styled.div`
  width: 3px;
  flex: 1;
  background-color: #0053E9;
`

const HorizBorder = styled.div`
  height: 3px;
  flex: 1;
  background-color: #0053E9;
`

const WindowContent = styled(Column)`
  background-color: white;
  overflow-y: scroll;
  overflow-x: hidden;
`

const Window = (props) => {
  const {
    onClose,
    children,
    iconSrc,
    title,
    ...rest
  } = props
  return (
    <Container {...rest}>
      <Background>
        <Row>
          <Topbar>
            {iconSrc && <WindowIcon src={iconSrc} />}
            <WindowTitle>{title}</WindowTitle>
            {onClose && <Button onClick={onClose} color='#FF3E00' square>X</Button>}
          </Topbar>
        </Row>
        <Row oy='scroll' n='1'>
          <Column>
            <VertBorder />
          </Column>
          <WindowContent n='1'>
            {children}
          </WindowContent>
          <Column>
            <VertBorder />
          </Column>
        </Row>
        <Row>
          <HorizBorder />
        </Row>
      </Background>
    </Container>
  )
}

export default Window
