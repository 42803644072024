import styled from 'styled-components'
import { withKeySound } from '../../libraries/with'

const Input = styled.input`
  border-radius: 8px;
  box-shadow: 1px 2px 2px rgba(0,0,0,0.25);
  appearance: none;
  border: 0;
  padding: 0 0.5rem 0 0.5rem;
  font-size: xx-large;
  max-width: 200px;
  outline: 0;
`

export default withKeySound(Input)
