import styled from 'styled-components'

const WordArtBlue = styled.p`
  font-family: Impact, sans-serif;
    color: #24c0fd;
    -webkit-text-stroke: 0.01em #0000aa;
    filter: progid:DXImageTransform.Microsoft.Glow(Color=#0000aa,Strength=1);
    text-shadow: 0.13em -0.13em 0px #0000aa;
    letter-spacing: -0.05em;
    margin: 0;
    font-size: 3rem;
`

export default WordArtBlue
