import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { setRootComment } from './libraries/comments'

const COMMENTS = ['hello king', 'hello queen', 'vad du än letar efter', 'så kommer du inte hitta det']
COMMENTS.reverse().forEach(setRootComment)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
